
import { shotLocationTypeOptions } from "../Utils/options";
import ButtonGroup from "../../../components/ButtonGroup.js";
import { editActivePoint, selectActivePoint } from "../store/editorSlice.js";
import { useSelector, useDispatch } from "react-redux";
import EditLastShots from "./EditLastShots.js";

const EditServePlus = ({ shot }) => {

    const dispatch = useDispatch();
    const activePoint = useSelector(selectActivePoint);

    const optionChangedHandler = (field, value) => {
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    return (
        <>
            <span>Serve Plus</span>
            <div className="mb-1">
                <ButtonGroup field="shotLocationId" value={shot.shotLocationId} options={shotLocationTypeOptions} onOptionChanged={optionChangedHandler} />
            </div>
        </>
    );
}

export default EditServePlus;