import React, { useEffect, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import { formatDetailedUtcDate } from "../../utils/app-utils";
import { useGetVideoJobDetailsQuery, } from "../api/apiSlice";
import { useGetCvMatchQuery, useGetMatchSnapshotsQuery } from "../api/snapshotsSlice";
import { useDeleteCvSnapshotMutation } from "../api/snapshotsSlice";
import {
    useEnqueueVideoMutation,
    useGetAllReadyVideosForLinkingQuery,
    useGetMoveRunStatusQuery,
    useLazyGetReadyVideoForLinkingByIdQuery,
    useMoveCvToVideoAppMutation,
    useMoveToFullMutation,
    useRefreshManualCollectedVideosMutation,
    useSendVideoMutation,
    useUnlinkVideoMutation,
    useUpdateEncodingJobIdMutation
} from "../api/collectedVideosSlice";
import { useAddNewMatchMutation, useEncodeVideoInstanceMutation, useGetAllPlayersQuery, useGetEventInstancesQuery, useSearchMatchesQuery } from "../api/completeMatchesSlice";
import { selectIsAdmin, selectUser, selectIsCV } from "../auth/authSlice";
import {
    filterVideosByTitle,
    selectFilteredVideos,
    selectSelectedVideo,
    setSelectedVideo,
    setVideos
} from "./VideosLinkningSlice";
import PathConstants from "../../routes/pathConstants";
import { useUpdateMatchInstanceStatusMutation } from "../api/matchInstancesApislice";
import { useCreateSnapshotMutation } from "../api/cvSlice";
import useShowToastr from "../../custom-hooks/useShowToastr";

export default function VideosLinkingPage() {
    const category = useParams().category;
    const [searchParams, setSearchParams] = useSearchParams();
    const videoProcessId = searchParams.get('videoProcessId');

    const label = category === "ttv"
        ? "Tennis TV"
        : category === "wtv"
            ? "WTA TV"
            : category === "ctv"
                ? "Challenger TV"
                : category === "recording-team"
                    ? "Recording Team"
                    : category === "wtt"
                        ? "ITF"
                        : category === "tc"
                            ? "Tennis Channel"
                            : category === "espn"
                                ? "ESPN"
                                : category == "dropbox"
                                    ? "Dropbox"
                                    : "IMG";

    const dispatch = useDispatch();
    const filteredVideos = useSelector(selectFilteredVideos);
    const selectedVideo = useSelector(selectSelectedVideo);
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isCV = useSelector(selectIsCV);
    const [isCVDataAvailable, setIsCVDataAvailable] = useState(false);
    const [snapshot, setSnapshot] = useState('');
    const [runId, setRunId] = useState(null);
    const [encodeJobId, setEncodeJobId] = useState(null);
    const [runSuccess, setRunSuccess] = useState(false);
    const [encodeSuccess, setEncodeSuccess] = useState(false);
    const { data: cvMatch, isLoading: cvMatchLoading, isSuccess: cvMatchSuccess, error: cvMatchError, status: cvMatchStatus } = useGetCvMatchQuery({ videoProcessId: selectedVideo?.id, snapshot: snapshot }, { skip: selectedVideo === null || snapshot == '' });
    const { data: snapshots, isLoading: snapshotsLoading, isSuccess: snapshotsSuccess, error: snapshotsError, status: snapshotsStatus, refetch: refetchMatchSnapshots } = useGetMatchSnapshotsQuery(videoProcessId, { skip: selectedVideo === null });
    const { data: matches, isLoading: matchesLoading, isSuccess: matchesSuccess, error: matchesError, status: matchesStatus, refetch: refetchMatchesCallBack } = useSearchMatchesQuery({
        p1Id: selectedVideo?.linked_metadata?.player1Id,
        p2Id: selectedVideo?.linked_metadata?.player2Id,
        eventInstanceId: selectedVideo?.linked_metadata?.eventInstanceId
    });
    const { data: moveStatusData, isLoading: moveStatusLoading, isSuccess: moveStatusSuccess, error: moveStatusError, status: moveStatusStatus, refetch: refreshRunStatus } = useGetMoveRunStatusQuery(runId, {
        skip: runId === null || runSuccess === true,
        pollingInterval: 3000
    });
    const { data: encodeJobDetails, isLoading: encodeJobDetailsLoading, isSuccess: encodeJobDetailsSuccess, error: encodeJobDetailsError, status: encodeJobDetailsStatus, refetch: refreshEncodeJobStatus } = useGetVideoJobDetailsQuery({ jobId: encodeJobId }, {
        skip: encodeJobId === undefined || encodeJobId === null || encodeSuccess === true,
        pollingInterval: 3000
    });
    const { data: videos, isLoading, error, isSuccess, status } = useGetAllReadyVideosForLinkingQuery({ category, isLinked: undefined }, { skip: category === undefined || category === null });
    const count = filteredVideos ? filteredVideos.length : 0;
    const [moveToFull, { isLoading: isMoving, isSuccess: isMoveSuccess, error: moveError, status: moveStatus }] = useMoveToFullMutation();
    const [addNewMatch, { isLoading: isAdding, isSuccess: isAddSuccess, error: addError, status: addStatus }] = useAddNewMatchMutation();
    const [moveCvToVideoApp, { isLoading: isMovingCv, isSuccess: isMoveCvSuccess, error: moveCvError, status: moveCvStatus }] = useMoveCvToVideoAppMutation();
    const [encodeVideoInstance, { isLoading: isEncoding, isSuccess: isEncodeSuccess, error: encodeError, status: encodeStatus }] = useEncodeVideoInstanceMutation();
    const [updateEncodingJobId, { isLoading: isUpdatingJobId, isSuccess: isUpdateJobIdSuccess, error: updateJobIdError, status: updateJobIdStatus }] = useUpdateEncodingJobIdMutation();
    const [sendVideo, { isLoading: isSending, isSuccess: isSendSuccess, error: sendError, status: sendStatus }] = useSendVideoMutation();
    const { data: players, isLoading: playersLoading, isSuccess: playersSuccess, error: playersError, status: playersStatus } = useGetAllPlayersQuery();
    const { data: events, isLoading: eventsLoading, isSuccess: eventsSuccess, error: eventsError, status: eventsStatus } = useGetEventInstancesQuery();
    const [unlinkVideo, { isLoading: isUnlinking, isSuccess: isUnlinkSuccess, error: unlinkError, status: unlinkStatus }] = useUnlinkVideoMutation();
    const [enqueueVideo, { isLoading: isEnqueuing, isSuccess: isEnqueueSuccess, error: enqueueError, status: enqueueStatus }] = useEnqueueVideoMutation();
    const [refreshManualCollectedVideos, { isLoading: isRefreshing, isSuccess: isRefreshSuccess, error: refreshError, status: refreshStatus }] = useRefreshManualCollectedVideosMutation();
    const [deleteSnapshot, { isLoading: isDeleting, isSuccess: isDeleteSuccess, error: deleteError, status: deleteStatus }] = useDeleteCvSnapshotMutation();
    const [getVideoProcessById] = useLazyGetReadyVideoForLinkingByIdQuery();
    const [createSnapshot, { isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot, isLoading: creatingSnapshot }] = useCreateSnapshotMutation();
    useShowToastr({ isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot, title: "Create Snapshot", successMsg: "Snapshot created successfully", errorMsg: "Snapshot creation failed" });

    const [updateMatchInstanceStatus, { isLoading: isUpdating, isSuccess: isUpdateSuccess, error: updateError, status: updateStatus }] = useUpdateMatchInstanceStatusMutation();

    const [p1, setP1] = useState(null);
    const [p2, setP2] = useState(null);
    const [event, setEvent] = useState(null);
    const [winner, setWinner] = useState(null);
    const [cvEnvironment, setCvEnvironment] = useState("prod");
    const [screenCoordinates, setScreenCoordinates] = useState(false);

    const matchRoundOptions = [
        { value: 0, label: '1st Round' },
        { value: 1, label: '2nd Round' },
        { value: 2, label: '3rd Round' },
        { value: 3, label: '4th Round' },
        { value: 7, label: 'Bronze' },
        { value: 6, label: 'Final Round' },
        { value: 100, label: 'N/A' },
        { value: 8, label: 'Pre-qualifying' },
        { value: 11, label: 'Qualifying' },
        { value: 9, label: 'Qualifying-1' },
        { value: 10, label: 'Qualifying-2' },
        { value: 4, label: 'Quarterfinals Round' },
        { value: 12, label: 'Robin' },
        { value: 13, label: 'Rubber 1' },
        { value: 14, label: 'Rubber 2' },
        { value: 15, label: 'Rubber 3' },
        { value: 16, label: 'Rubber 4' },
        { value: 17, label: 'Rubber 5' },
        { value: 5, label: 'Semifinals Round' },
    ];

    useEffect(() => {
        if (videoProcessId) {
            getVideoProcessById(videoProcessId).then((response) => {
                dispatch(setSelectedVideo(response.data));
            });
        } else {
            dispatch(setSelectedVideo(null));
        }
    }, [videoProcessId]);

    useEffect(() => {
        if (moveStatusSuccess) {
            if (moveStatusData.status === "Succeeded") {
                refetchMatchesCallBack();
                setRunSuccess(true);
            }
        }
    }, [moveStatusData, moveStatusLoading, moveStatusSuccess, moveStatusError, moveStatusStatus]);


    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (isEnqueueSuccess) {
            toastr.success("Re-downlaod request posted successfully", "Re-Download Video");
        }
        else if (enqueueError) {
            toastr.error("Re-download request failed to submit", "Re-Download Video");
        }
    }, [isEnqueueSuccess, enqueueError, enqueueStatus]);


    useEffect(() => {
        if (isSuccess) {
            dispatch(setVideos(videos));
        }
    }, [videos]);

    useEffect(() => {
        if (isMoveCvSuccess) {
            refetchMatchesCallBack();
        }
    }, [isMoveCvSuccess]);


    useEffect(() => {
        if (cvMatchSuccess) {
        }
    }, [cvMatch]);


    useEffect(() => {
        if (matchesSuccess) {
            if (matches.length > 0 && selectedVideo) {
                let vidInstance = matches.find((x) => x.matchInstances.find((y) => y.matchDataTypeId === 4) !== undefined);
                setIsCVDataAvailable(selectedVideo.player1Id !== null
                    && selectedVideo.player2Id !== null
                    && selectedVideo.eventInstanceId !== null
                    && selectedVideo.recent_cv_status?.status === "FINISHED"
                    && vidInstance !== undefined);
            }
        } else {
            setIsCVDataAvailable(false);
        }
    }, [matches, matchesSuccess, matchesError]);

    useEffect(() => {
        if (selectedVideo) {
            if (selectedVideo.videoMoveRunId !== null) {
                setRunId(selectedVideo.videoMoveRunId);
            }
            else {
                setRunId(null);
            }

            if (selectedVideo.videoEncodingJobId && selectedVideo.videoEncodingJobId !== null) {
                setEncodeJobId(selectedVideo.videoEncodingJobId);
            }
            else {
                setEncodeJobId(null);
            }
            setSnapshot('');

            if (selectedVideo.isLinked === true && players) {
                const p1 = players.find(x => x.playerId === selectedVideo.linked_metadata.player1Id);
                const p2 = players.find(x => x.playerId === selectedVideo.linked_metadata.player2Id);
                const winner = players.find(x => x.playerId === selectedVideo.linked_metadata.winnerId);
                setP1(p1);
                setP2(p2);
                setWinner(winner);
            } else {
                setP1(null);
                setP2(null);
                setWinner(null);
            }

            if (selectedVideo.isLinked === true && events) {
                const event = events.find(x => x.eventInstanceId === selectedVideo.linked_metadata.eventInstanceId);
                setEvent(event);
            } else {
                setEvent(null);
            }

        } else {
            setRunId(null);
            setEncodeJobId(null);
            setSnapshot('');
            setIsCVDataAvailable(false);
        }
    }, [selectedVideo]);

    useEffect(() => {
        if (encodeJobDetailsSuccess) {
            if (encodeJobDetails.result === "Success") {
                setEncodeSuccess(true);
            }
        }
    }, [encodeJobDetails]);


    useEffect(() => {
        if (snapshotsSuccess) {
            if (snapshots.length > 0) {
                setSnapshot(snapshots[snapshots.length - 1]);
            }
        }
    }, [snapshots]);

    useEffect(() => {
        if (playersSuccess) {
            if (selectedVideo && selectedVideo.isLinked === true) {
                const p1 = players.find(x => x.playerId === selectedVideo.linked_metadata.player1Id);
                const p2 = players.find(x => x.playerId === selectedVideo.linked_metadata.player2Id);
                const winner = players.find(x => x.playerId === selectedVideo.linked_metadata.winnerId);
                setP1(p1);
                setP2(p2);
                setWinner(winner);
            }
        }
    }, [players]);

    useEffect(() => {
        if (eventsStatus) {
            if (selectedVideo && selectedVideo.isLinked === true) {
                const event = events.find(x => x.eventInstanceId === selectedVideo.linked_metadata.eventInstanceId);
                setEvent(event);
            }
        }
    }, [events]);

    const downloadSnapshotHandler = () => {
        let url = `${process.env.REACT_APP_API_URL}/videos/${selectedVideo.id}/snapshots/download/${snapshot}?fileName=${selectedVideo.title}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const downloadCVReportHandler = () => {
        let url = `${process.env.REACT_APP_API_URL}/videos/${selectedVideo.id}/snapshots/download/report?fileName=${selectedVideo.title}`;
        window.open(url, "_blank", "noopener,noreferrer");
    }

    if (!user) return <Spinner />;
    else if (!isAdmin && !isCV) return <NotAuthorizedPage />;
    if (isLoading) return <Spinner />;
    if (error) return <div>
        {status} {error.status} {JSON.stringify(error.error)}
    </div>;

    return (
        <div className="row">
            <div className="col-4 mb-3">
                <div className="card mt-1 min-vh-75 max-vh-75">
                    <div className="card-header">
                        <div className="row justify-content-between">
                            <h5>{label} Videos ({count})</h5>
                            {(label === "Dropbox" || label === "Recording Team") && (
                                <button className="btn btn-primary mr-1 mb-1 fs--1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        refreshManualCollectedVideos().then((response) => {
                                            toastr.options.positionClass = "toast-bottom-right";
                                            toastr.success("Refresh request posted successfully, Please refresh entire page after 1 min", "Refresh Videos");
                                        }).catch((error) => {
                                            toastr.options.positionClass = "toast-bottom-right";
                                            toastr.error("Refresh request failed to submit", "Refresh Videos");
                                        });
                                    }}>
                                    Refresh
                                </button>)
                            }
                            <div className="col-12 mt-1">
                                <input type="text" className="form-control" placeholder="Search by name" onChange={(x) => {
                                    dispatch(filterVideosByTitle(x.target.value));
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 scrollbar perfect-scrollbar">
                        <div className="list-group">
                            {filteredVideos.map(video => {
                                let classes = "list-group-item list-group-item-action";
                                if (video.title === selectedVideo?.title) {
                                    classes += " bg-500 text-white";
                                }
                                return (
                                    <a
                                        style={{ cursor: "pointer" }}
                                        key={video.id}
                                        className={classes}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            setSearchParams({ videoProcessId: video.id });
                                        }}
                                    >
                                        <strong>{video.title}</strong>
                                        {video.isCvCompleted === true && (
                                            <span className="badge badge-success ml-2">CV</span>
                                        )}
                                        {video.isCvNew === true && (
                                            <span className="badge badge-danger ml-2">CV</span>
                                        )}
                                        {video.isCvProcessing === true && (
                                            <span className="badge badge-warning ml-2">CV</span>
                                        )}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-8 mb-3">
                {selectedVideo !== null && (
                    <div>
                        <div className="card mt-1">
                            <div className="card-header d-flex row align-items-center">
                                <h5>{selectedVideo.title}</h5>
                                <button className="btn btn-primary ml-3 p-1" onClick={() => getVideoProcessById(selectedVideo.id).then((response) => {
                                    dispatch(setSelectedVideo(response.data));
                                })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                    </svg>
                                </button>
                            </div>
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Id</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <strong>{selectedVideo.id}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <CopyToClipboard text={selectedVideo.source_url}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Source URL</button>
                                </CopyToClipboard>
                                <CopyToClipboard text={selectedVideo.browserURL}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Browser URL</button>
                                </CopyToClipboard>
                                <CopyToClipboard text={selectedVideo.downloadURL}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Download URL</button>
                                </CopyToClipboard>
                                <button className="btn btn-primary mr-1 mb-1 fs--1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        enqueueVideo({ id: selectedVideo.metadata.id, category: category });
                                    }}>
                                    Re-Download Video
                                </button>
                                <div className="row mt-2 ml-0 align-items-center">
                                    <div className="btn-group mr-2">
                                        <button className="btn btn-sm dropdown-toggle btn-light" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{cvEnvironment === "dev" ? "CV Development" : "CV Production"}
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" onClick={() => setCvEnvironment("dev")}>CV Development</a>
                                            <a className="dropdown-item" onClick={() => setCvEnvironment("prod")}>CV Production</a>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-sm fs--1"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            sendVideo({ videoId: selectedVideo.id, env: cvEnvironment, screenCoordinates: screenCoordinates });
                                        }}>
                                        Send Video To CV
                                    </button>
                                    <div className="form-check ml-2">
                                        <input className="form-check-input" type="checkbox" id="screenCoordinates" checked={screenCoordinates} onChange={(e) => setScreenCoordinates(e.target.checked)} />
                                        <label className="form-check-label" htmlFor="screenCoordinates">
                                            Screen Coordinates
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {matches?.length > 0 && (
                    matches.map((match) => {
                        return (
                            <div key={match.matchId} className="card mt-1">
                                <div className="card-header">
                                    <h5>{match.name}</h5>
                                    {!selectedVideo.isLinked &&
                                        <div className="d-flex row mt-2">
                                            <span className="text-danger">Waiting for linking data to be updated the matches below may change.</span>
                                        </div>
                                    }
                                </div>
                                <div className="card-body p-0">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">File Name</th>
                                                <th scope="col">File Status</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {match.matchInstances.map((instance) => {
                                                return (
                                                    <tr key={instance.matchInstanceId}>
                                                        <td>{instance.matchInstanceId}</td>
                                                        <td>
                                                            {instance.files[0].fileName}
                                                        </td>
                                                        <td>{instance.matchInstanceStatusId}
                                                            <br />
                                                            {runId && moveStatusSuccess && instance.matchDataTypeId === 4 && (
                                                                <strong>Moving {moveStatusData.status}</strong>
                                                            )}
                                                            {encodeJobId && encodeJobDetailsSuccess && instance.matchDataTypeId === 4 && (
                                                                <strong>Encoding {encodeJobDetails.status}</strong>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(instance.matchDataTypeId === 4 || instance.matchDataTypeId === 9) && (
                                                                <button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    e.nativeEvent.stopImmediatePropagation();
                                                                    let videoBlobPath =
                                                                        selectedVideo.source_url
                                                                            .replace(
                                                                                "https://gsavideo.blob.core.windows.net/collected-videos/",
                                                                                "").replace(
                                                                                    "https://gsavideo.blob.core.windows.net/recording-team/",
                                                                                    "");
                                                                    moveToFull({
                                                                        videoId: selectedVideo.id,
                                                                        videoBlobPath: videoBlobPath,
                                                                        destinationPath: `${match.eventInstanceId}/${match.matchId}/${instance.matchInstanceId}.mp4`,
                                                                        matchInstanceId: instance.matchInstanceId,
                                                                    }).then((response) => {
                                                                        setRunId(response.data.runId);
                                                                    });
                                                                }}>Move</button>
                                                            )}
                                                            {(instance.matchDataTypeId === 4 || instance.matchDataTypeId === 9) && (encodeJobId === null) && instance.matchInstanceStatusId === 40 && (<button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                e.nativeEvent.stopImmediatePropagation();
                                                                encodeVideoInstance({
                                                                    matchId: match.matchId
                                                                }).then((response) => {
                                                                    setEncodeJobId(response.data);
                                                                    updateEncodingJobId({ id: selectedVideo.id, jobId: response.data });
                                                                });
                                                            }}>Encode</button>
                                                            )}
                                                            {(instance.matchDataTypeId === 8) && instance.matchInstanceStatusId === 40 && (<button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                e.nativeEvent.stopImmediatePropagation();
                                                                updateMatchInstanceStatus({
                                                                    matchId: match.matchId,
                                                                    matchInstanceId: instance.matchInstanceId,
                                                                    statusId: 30
                                                                }).then((response) => {
                                                                    refetchMatchesCallBack();
                                                                });
                                                            }}>QA</button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <button className="btn btn-sm btn-warning my-3 ml-2 text-nowrap" disabled={!selectedVideo.isLinked} onClick={() => {
                                                unlinkVideo(selectedVideo.id).then(() => {
                                                    dispatch(setSelectedVideo({ ...selectedVideo, isLinked: false }))
                                                });
                                            }}>
                                                Mark Not Linked
                                            </button>
                                            {!selectedVideo.isLinked &&
                                                <button className="btn btn-primary btn-sm ml-3" onClick={() => {
                                                    let url = `${process.env.REACT_APP_PUBLIC_URL}/codersvideoslinking/${category}?videoProcessId=${selectedVideo.id}`;
                                                    window.open(url, "_blank", "noopener,noreferrer");
                                                }}>Go to linking page</button>}
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary mr-1"
                                        disabled={
                                            selectedVideo.player1Id === null
                                            || selectedVideo.player2Id === null
                                            || selectedVideo.eventInstanceId === null
                                            || match.matchInstances.find((x) => x.matchDataTypeId === 4) === undefined
                                        } onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            refetchMatchesCallBack();
                                            if (runId !== null) {
                                                refreshRunStatus();
                                            }
                                        }}>
                                        Refresh
                                    </button>
                                </div>
                            </div>
                        );
                    })
                )}

                {matches?.length === 0 && selectedVideo && selectedVideo.isLinked === true && (
                    <div className="card mt-1">
                        <div className="card-header">
                            <h5>Create New Match</h5>
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Property</th>
                                        <th scope="col">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Player 1</td>
                                        <td>{p1?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Player 2</td>
                                        <td>{p2?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Event</td>
                                        <td>{event?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Round</td>
                                        <td>{matchRoundOptions.find(x => x.value === selectedVideo.linked_metadata.roundId).label}</td>
                                    </tr>
                                    <tr>
                                        <td>Winner</td>
                                        <td>{winner?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Result</td>
                                        <td>{selectedVideo.linked_metadata.result}</td>
                                    </tr>
                                    <tr>
                                        <td>Result Type</td>
                                        <td>{selectedVideo.linked_metadata.resultType}</td>
                                    </tr>
                                    <tr>
                                        <td>Match Type</td>
                                        <td>{selectedVideo.linked_metadata.matchType}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="card-footer">
                            {/* Sumbit btn if winner and result filled */}
                            <button className="btn btn-primary"
                                disabled={
                                    selectedVideo.linked_metadata.winnerId === null
                                    || selectedVideo.linked_metadata.result === null || selectedVideo.linked_metadata.result === ""
                                    || selectedVideo.linked_metadata.resultType === null
                                    || selectedVideo.linked_metadata.matchType === null
                                    || selectedVideo.linked_metadata.player1Id === null
                                    || selectedVideo.linked_metadata.player2Id === null
                                    || selectedVideo.linked_metadata.eventInstanceId === null
                                } onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    addNewMatch({
                                        playerId1: selectedVideo.linked_metadata.player1Id,
                                        playerId2: selectedVideo.linked_metadata.player2Id,
                                        winnerId: selectedVideo.linked_metadata.winnerId,
                                        eventInstanceId: selectedVideo.linked_metadata.eventInstanceId,
                                        roundId: selectedVideo.linked_metadata.roundId,
                                        result: selectedVideo.linked_metadata.result,
                                        resultType: selectedVideo.linked_metadata.resultType,
                                        matchType: selectedVideo.linked_metadata.matchType,
                                    }).then((response) => {
                                        refetchMatchesCallBack();
                                    });
                                }}>Add New Match</button>
                            <button className="btn btn-sm btn-warning my-3 ml-3 text-nowrap" onClick={() => {
                                unlinkVideo(selectedVideo.id).then(() => {
                                    dispatch(setSelectedVideo({ ...selectedVideo, isLinked: false }))
                                });
                            }}>
                                Mark Not Linked
                            </button>
                        </div>
                    </div>
                )}

                {matches?.length === 0 && selectedVideo && !selectedVideo.isLinked && (
                    <div className="card mt-1">
                        <div className="card-body d-flex row align-items-center">
                            <h5>Please complete linking first to create new match</h5>
                            <button className="btn btn-primary ml-3" onClick={() => {
                                let url = `${process.env.REACT_APP_PUBLIC_URL}/codersvideoslinking/${category}?videoProcessId=${selectedVideo.id}`;
                                window.open(url, "_blank", "noopener,noreferrer");
                            }}>Go to linking page</button>
                        </div>
                    </div>
                )}

                {selectedVideo !== null && selectedVideo.recent_cv_status?.status === "FINISHED" && (
                    <div className="card mt-1">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="row ml-2 align-items-center">
                                    <h5 className="mr-2 ">CV Data</h5>
                                    {!isMovingCv && <div className='d-flex row ml-auto'>
                                        <span style={{ height: "fit-content" }} className="badge badge-pill badge-dark mr-2">{!selectedVideo.editorTracker.assignee || selectedVideo.editorTracker.assignee == "" ? "Not Assigned" : selectedVideo.editorTracker.assignee}</span>
                                        <span className={`badge badge-pill badge-warning mr-2`}>{selectedVideo.editorTracker.status}</span>
                                        <span className={`badge badge-pill badge-secondary`}>{selectedVideo.editorTracker.typeOfCoding}</span>
                                    </div>}
                                    {isMovingCv && (
                                        <Spinner />
                                    )}
                                </div>
                                <div className="form-row">
                                    <div className="mr-2">
                                        <select id="snapshotsSelect" className="form-control" value={snapshot} onChange={(x) => {
                                            if (x.target.value === 'Select Snapshot') {
                                                setSnapshot('');
                                            } else {
                                                setSnapshot(x.target.value);
                                            }
                                        }}>
                                            <option value={null}>Select Snapshot</option>
                                            {snapshotsSuccess && snapshots.map((option) => {
                                                return (
                                                    <option key={option} value={option}>{formatDetailedUtcDate(option)}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {snapshot == '' && <div>
                                        <button type="button" className="btn btn-primary mr-1" disabled={creatingSnapshot} onClick={() => createSnapshot(selectedVideo.id).then(response => {
                                            refetchMatchSnapshots();
                                            setSnapshot('');
                                        })}>
                                            {creatingSnapshot ? "Creating..." : "Create Snapshot"}
                                        </button>
                                    </div>}
                                    {snapshots?.length > 0 && snapshot != '' && <div>
                                        <a className="btn btn-primary mr-1" href={`${process.env.REACT_APP_PUBLIC_URL}/${PathConstants.EDITOR}?videoProcessId=${selectedVideo.id}&snapshot=${snapshot}`} target="_blank" rel="noreferrer">
                                            Open Editor
                                        </a>
                                    </div>}
                                    <div>
                                        {isCVDataAvailable && (
                                            <button className="btn btn-primary mr-1"
                                                disabled={snapshot === ''}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    moveCvToVideoApp({ videoId: selectedVideo.id, snapshot: snapshot });
                                                }}>
                                                Move CV to Video App
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {cvMatchLoading && (<Spinner />)}
                                {cvMatchSuccess && !cvMatchLoading && cvMatch && snapshot !== '' && (
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Property</th>
                                                <th scope="col">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Created By</td>
                                                <td>{cvMatch?.createdBy}</td>
                                            </tr>
                                            <tr>
                                                <td>Created On</td>
                                                <td>{formatDetailedUtcDate(cvMatch?.createdAt)}</td>
                                            </tr>
                                            <tr>
                                                <td>Points</td>
                                                <td>{cvMatch.points.length}</td>
                                            </tr>
                                            <tr>
                                                <td>Needs Review</td>
                                                <td>{cvMatch.points.filter((p) => p.needsReview).length}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot >
                                            <div className="mt-3">
                                                <button className="btn btn-primary mr-1"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                        downloadSnapshotHandler();
                                                    }}>
                                                    Download Snapshot
                                                </button>
                                                <button className="btn btn-danger mr-1"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                        deleteSnapshot({ videoProcessId: selectedVideo.id, snapshot: snapshot }).then(response => {
                                                            refetchMatchSnapshots();
                                                            setSnapshot('');
                                                        });
                                                    }}>
                                                    Delete Snapshot
                                                </button>
                                            </div>
                                        </tfoot>
                                    </table>
                                )}
                            </div>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-secondary mr-1"
                                onClick={(e) => {
                                    downloadCVReportHandler();
                                }}>
                                Download CV Report
                            </button>
                            <button className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    refetchMatchSnapshots();
                                }}>
                                Refresh
                            </button>
                        </div>
                    </div>
                )}

                {selectedVideo !== null && selectedVideo.cv_status_history?.length > 0 && (
                    <div className="card mt-1">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <h5>CV Logs ({selectedVideo.cv_env === "dev" ? "Development" : "Production"}) {selectedVideo.cv_version ? `(Version ${selectedVideo.cv_version})` : ""}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Status</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Created On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedVideo.cv_status_history.map((status) => {
                                            return (
                                                <tr key={status.receivedAt}>
                                                    <td>{status.status}</td>
                                                    <td>{status.message}</td>
                                                    <td>{formatDetailedUtcDate(status.receivedAt)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
}