import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/api/apiSlice";
import { collectedVideosSlice } from "./features/api/collectedVideosSlice";
import { completeMatchesSlice } from "./features/api/completeMatchesSlice";
import { playerGroupsSlice } from "./features/api/playerGroupsSlice";
import { reportExecutionSlice } from "./features/api/reportExecutionSlice";
import { lookupsSlice } from "./features/api/lookupsSlice";
import authReducer from "./features/auth/authSlice";
import editorReducer from "./features/editor/store/editorSlice";
import editHESlice from "./features/editHE/store/editHESlice";
import VideosLinkningSlice from "./features/videosLinking/VideosLinkningSlice";
import VideosLinkningCodersSlice from "./features/videosLinkingCoders/VideosLinkningCodersSlice";
import drawsReportsSlice from "./features/drawsReports/DrawsReportsSlice";
import { accountSlice } from "./features/api/accountSlice";
import { oncSlice } from "./features/api/oncSlice";
import { hangfireApiSlice } from "./features/api/hangfireApiSlice";
import { matchInstanceApiSlice } from "./features/api/matchInstancesApislice";
import { cvSlice } from "./features/api/cvSlice";
import { drawsSlice } from "./features/api/drawsSlice";
import { userSlice } from "./features/api/userSlice";
import { reportsSlice } from "./features/api/reportsSlice";
import { snapshotsSlice } from "./features/api/snapshotsSlice";
import { oktaSlice } from "./features/api/oktaSlice";
import clientsSlice from "./features/clients/clientsSlice";

const store = configureStore({
  reducer: {
    editor: editorReducer,
    auth: authReducer,
    editHE: editHESlice,
    videosLinking: VideosLinkningSlice,
    videosLinkingCoders: VideosLinkningCodersSlice,
    drawsReports: drawsReportsSlice,
    clients: clientsSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [collectedVideosSlice.reducerPath]: collectedVideosSlice.reducer,
    [completeMatchesSlice.reducerPath]: completeMatchesSlice.reducer,
    [reportExecutionSlice.reducerPath]: reportExecutionSlice.reducer,
    [playerGroupsSlice.reducerPath]: playerGroupsSlice.reducer,
    [lookupsSlice.reducerPath]: lookupsSlice.reducer,
    [accountSlice.reducerPath]: accountSlice.reducer,
    [oncSlice.reducerPath]: oncSlice.reducer,
    [hangfireApiSlice.reducerPath]: hangfireApiSlice.reducer,
    [matchInstanceApiSlice.reducerPath]: matchInstanceApiSlice.reducer,
    [cvSlice.reducerPath]: cvSlice.reducer,
    [drawsSlice.reducerPath]: drawsSlice.reducer,
    [userSlice.reducerPath]: userSlice.reducer,
    [reportsSlice.reducerPath]: reportsSlice.reducer,
    [snapshotsSlice.reducerPath]: snapshotsSlice.reducer,
    [oktaSlice.reducerPath]: oktaSlice.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      apiSlice.middleware,
      collectedVideosSlice.middleware,
      completeMatchesSlice.middleware,
      reportExecutionSlice.middleware,
      playerGroupsSlice.middleware,
      lookupsSlice.middleware,
      accountSlice.middleware,
      oncSlice.middleware,
      hangfireApiSlice.middleware,
      matchInstanceApiSlice.middleware,
      cvSlice.middleware,
      drawsSlice.middleware,
      userSlice.middleware,
      reportsSlice.middleware,
      snapshotsSlice.middleware,
      oktaSlice.middleware
    );
  },
});

export default store;
