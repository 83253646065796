import { useSelector } from "react-redux";
import { selectActivePoint } from "../store/editorSlice";
import EditServePlus from "../EditShot/EditServePlus";
import EditLastShots from "../EditShot/EditLastShots";
import * as constants from "../Utils/constants";

const EditGS = () => {
    const activePoint = useSelector(selectActivePoint);

    const returnShot = activePoint.shots.find((s) => s.shotOrderId === constants.RETURN_SHOT_ORDER_ID);
    const servePlusOneShot = activePoint.shots.find((s) => s.shotOrderId === constants.SERVE_PLUS_ONE_SHOT_ORDER_ID);
    const secondToLastShot = activePoint.shots.find((s) => s.shotOrderId === constants.SECOND_TO_LAST_SHOT_ORDER_ID);
    const lastShot = activePoint.shots.find((s) => s.shotOrderId === constants.LAST_SHOT_ORDER_ID);

    return (
        <>
            {
                secondToLastShot && <EditServePlus shot={servePlusOneShot} />
            }
            {
                !servePlusOneShot && returnShot && <EditLastShots shot={returnShot} />
            }
            {
                servePlusOneShot && !secondToLastShot && !lastShot && <EditLastShots shot={returnShot} />
            }
            {
                servePlusOneShot && (!secondToLastShot || !lastShot) && <EditLastShots shot={servePlusOneShot} />
            }
            {
                secondToLastShot &&
                <>
                    <hr className="w-75"></hr>
                    <EditLastShots shot={secondToLastShot} />
                </>
            }
            {
                lastShot &&
                <>
                    <hr className="w-75"></hr>
                    <EditLastShots shot={lastShot} />
                </>
            }
        </>
    )
}

export default EditGS;